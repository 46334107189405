'use client';

import React from 'react';
import type { ButtonProps } from '@mui/material/Button';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import styles from './StatusCode.module.scss';
interface StatusCodeProps {
  code: string | number;
  title: string;
  description: string;
  actionProps?: Partial<ButtonProps>;
}
const StatusCode: React.FC<StatusCodeProps> = ({
  code,
  title,
  description,
  actionProps
}) => {
  const {
    href,
    ...restActionProps
  } = actionProps || {};
  const ButtonWrapper = href ? Link : React.Fragment;
  const buttonWrapperProps = href ? {
    prefetch: false,
    href
  } : {};
  return <div className={styles.root} data-sentry-component="StatusCode" data-sentry-source-file="StatusCode.tsx">
      <Typography variant="mono1" component="h2" className={styles.code} data-sentry-element="Typography" data-sentry-source-file="StatusCode.tsx">
        {code}
      </Typography>
      <div className={styles.maxWidth}>
        <Typography variant="h1bk" component="h1" className={styles.title} align="center" data-sentry-element="Typography" data-sentry-source-file="StatusCode.tsx">
          {title}
        </Typography>
        <Typography variant="paragraph9" className={styles.description} data-sentry-element="Typography" data-sentry-source-file="StatusCode.tsx">
          {description}
        </Typography>
        {/* @ts-ignore URL can't be undefined */}
        <ButtonWrapper {...buttonWrapperProps} data-sentry-element="ButtonWrapper" data-sentry-source-file="StatusCode.tsx">
          <Button variant="primary" size="large" type="button" {...restActionProps} data-sentry-element="Button" data-sentry-source-file="StatusCode.tsx" />
        </ButtonWrapper>
      </div>
    </div>;
};
export default StatusCode;